import logo from './assets/logo.png';
import DesktopCanvas from './components/desktop-canvas';
import MobileCanvas from './components/mobile-canvas';
import MediaQuery from 'react-responsive'

function App() {
  return (
    <div>
      <div className='flex justify-center items-center my-4 space-x-4 mx-2 lg:mx-10'>
        <div className='mode-sec-bg rounded-3xl px-8 flex justify-center items-center space-x-3 py-3'>
          <img className="w-auto h-12 lg:h-12 rounded-full" src={logo} alt="" />
          <div>
            <h1 className='leading-none text-end md:text-center text-3xl font-bold uppercase'>Angry Apes Society</h1>
            <p className='leading-none text-end md:text-start'>Banner Maker</p>
          </div>
        </div>
      </div>
      <MediaQuery maxWidth={1023.9}>
        <MobileCanvas />
      </MediaQuery>
      <MediaQuery minWidth={1024}>
        <DesktopCanvas />
      </MediaQuery>
    </div>
  );
}

export default App;
