import React, { useEffect, useState } from 'react';
import { FabricJSCanvas, useFabricJSEditor } from "fabricjs-react";
import { fabric } from 'fabric';
import { SketchPicker } from 'react-color';
import NftsData from '../assets/nfts-data.json';
import BGData from '../assets/bg-data.json';

function DesktopCanvas() {
    const [color, setColor] = useState('#fff');
    const [currentFont, setCurrentFont] = useState('Arial');
    const [isLoading, setIsLoading] = useState(false);
    const [activeTab, setActiveTab] = useState('BGs'); 
    const [colors] = useState([
        '#4ab1b6', // Turquoise
        '#000000', // Black
        '#f8b212', // Juno
        '#ffffff', // White
        '#bdb779', // Sandy Yellow
        '#e1e0ab', // Light Yellow
        '#e4bbd6', // Light Pink
        '#a9d0e3', // Light Blue
        '#a69fcd', // Lila
        '#8cdfbe', // Light Green
        '#73c2ce', // Azure
        '#9b9695', // Grey
        '#8a9155', // Army Green
        '#6c666d', // Solitaire
        '#312932', // Alcatraz
        '#9c9959', // Dalai Lama
        '#8cdfbe', // The Dictator
        '#7fd9e2', // Enos
        '#8b2418', // Che
        '#74af91', // Van Gogh
        '#7f6277', // Hell Rider
        '#8dddcd', // Type-2
    ]);
    const [IsTextColor, setIsTextColor] = useState(false);
    const { editor, onReady } = useFabricJSEditor();

    const handleChangeComplete = (color) => {
        setColor(color.hex);
    };

    const onAddText = () => {
        const text = new fabric.IText('Add Text Here', {
            left: 100, // X position
            top: 10, // Y position
            fontSize: 25
        });

        // Add the text object to the canvas
        editor?.canvas.add(text);

        // Make sure to render the canvas to see the changes
        editor?.canvas.renderAll();
    };

    const deleteSelected = () => {
        editor.deleteSelected();
    };

    const duplicateSelected = () => {
        const activeObject = editor?.canvas.getActiveObject();

        if (activeObject) {
            const clone = fabric.util.object.clone(activeObject);

            clone.set({ left: clone.left + 10, top: clone.top + 10 });
            editor?.canvas.add(clone);
            editor?.canvas.setActiveObject(clone);
        }
    };

    const bringToFront = () => {
        const activeObject = editor?.canvas.getActiveObject();
        if (activeObject) {
            activeObject.bringForward(activeObject);
            activeObject.canvas.preserveObjectStacking = true;
            editor?.canvas.requestRenderAll();
        }
    };

    const sendToBack = () => {
        const activeObject = editor?.canvas.getActiveObject();
        if (activeObject) {
            activeObject.sendBackwards(activeObject);
            activeObject.canvas.preserveObjectStacking = true;
            editor?.canvas.requestRenderAll();
        }
    };

    const flipHorizontal = () => {
        const activeObject = editor?.canvas.getActiveObject();
        if (activeObject) {
            activeObject.set('flipX', !activeObject.flipX);
            editor?.canvas.requestRenderAll();
        }
    };

    const handleFontChange = (event) => {
        changeFont(event.target.value);
    };

    const changeFont = (fontFamily) => {
        setCurrentFont(fontFamily);
        var activeObject = editor?.canvas.getActiveObject();

        if (activeObject && activeObject.type === 'i-text') {
            activeObject.set({ fontFamily: fontFamily });
            editor?.canvas.requestRenderAll(); // Refresh the canvas to apply changes
        }
    }

    const handleUploadImage = (event) => {
        const file = event.target.files[0];
        if (!file) {
            return;
        }
        const reader = new FileReader();
        reader.onload = function (f) {
            const data = f.target.result;
            fabric.Image.fromURL(data, (img) => {
                img.scale(0.1).set({
                    left: 100,
                    top: 10,
                    angle: 0
                });

                editor?.canvas.add(img)
            });
        };
        reader.readAsDataURL(file);
    };

    // const handleSaveAsImage = () => {
    //     // Assuming 'editor' is your main canvas editor object
    //     const originalCanvas = editor.canvas;

    //     // New target dimensions
    //     const targetWidth = 1500;
    //     const targetHeight = 500;

    //     // Create a new, temporary canvas
    //     const tempCanvasEl = document.createElement('canvas');
    //     tempCanvasEl.width = targetWidth; // Desired export width
    //     tempCanvasEl.height = targetHeight; // Desired export height
    //     const tempCanvas = new fabric.Canvas(tempCanvasEl);

    //     // Ensure the background is correctly set on the temporary canvas
    //     const setBackgroundImage = (background) => {
    //         if (background instanceof fabric.Image) {
    //             // Scale background image to fit new dimensions
    //             background.scaleToWidth(tempCanvasEl.width);
    //             background.scaleToHeight(tempCanvasEl.height);
    //             tempCanvas.setBackgroundImage(background, tempCanvas.renderAll.bind(tempCanvas), {
    //                 originX: 'left',
    //                 originY: 'top',
    //                 scaleX: tempCanvasEl.width / background.width,
    //                 scaleY: tempCanvasEl.height / background.height
    //             });
    //         } else {
    //             // If a solid background color is used, just copy it
    //             tempCanvas.setBackgroundColor(originalCanvas.backgroundColor, tempCanvas.renderAll.bind(tempCanvas));
    //         }
    //     };

    //     if (originalCanvas.backgroundImage) {
    //         // Clone the background image to avoid modifying the original canvas background
    //         originalCanvas.backgroundImage.clone((clonedBg) => {
    //             setBackgroundImage(clonedBg);
    //         });
    //     } else {
    //         setBackgroundImage(originalCanvas.backgroundColor);
    //     }

    //     // Scaling factors to fit the new canvas size
    //     const scaleX = targetWidth / originalCanvas.width;
    //     const scaleY = targetHeight / originalCanvas.height;

    //     // Clone all objects from the original canvas to the temporary one
    //     originalCanvas.getObjects().forEach(obj => {
    //         obj.clone(clonedObj => {
    //             clonedObj.set({
    //                 scaleX: clonedObj.scaleX * scaleX,
    //                 scaleY: clonedObj.scaleY * scaleY,
    //                 left: clonedObj.left * scaleX,
    //                 top: clonedObj.top * scaleY,
    //             });
    //             clonedObj.setCoords();
    //             tempCanvas.add(clonedObj);
    //         });
    //     });

    //     // Export the temporary canvas to an image after ensuring all objects are loaded
    //     setTimeout(() => {
    //         tempCanvasEl.toBlob(blob => {
    //             const link = document.createElement('a');
    //             link.download = 'aas-banner.png';
    //             link.href = URL.createObjectURL(blob);
    //             link.click();
    //             // Clean up
    //             link.remove();
    //             tempCanvas.dispose();
    //         }, 'image/png');
    //     }, 1000); // Adjust timeout if necessary
    // };

    const handleSaveAsImage = () => {
        const targetWidth = 1500;
        const targetHeight = 500;
    
        // Create a new, temporary canvas
        const tempCanvasEl = document.createElement('canvas');
        tempCanvasEl.width = targetWidth;
        tempCanvasEl.height = targetHeight;
        const tempCtx = tempCanvasEl.getContext('2d');
    
        // Assuming editor.canvas is a fabric.Canvas instance
        const originalCanvas = editor.canvas;
        const originalScaleX = targetWidth / originalCanvas.getWidth();
        const originalScaleY = targetHeight / originalCanvas.getHeight();
    
        // If there's a background image, draw it first (scaled as needed)
        if (originalCanvas.backgroundImage) {
            const bgImg = originalCanvas.backgroundImage.getElement();
            tempCtx.drawImage(bgImg, 0, 0, targetWidth, targetHeight);
        } else {
            // If using a solid background color, fill the canvas with it
            tempCtx.fillStyle = originalCanvas.backgroundColor || "#ffffff";
            tempCtx.fillRect(0, 0, targetWidth, targetHeight);
        }
    
        // Draw each object on the temporary canvas
        originalCanvas.getObjects().forEach(obj => {
            obj.clone(clonedObj => {
                // Temporarily adjust object properties for drawing
                const originalObjProps = {
                    left: clonedObj.left,
                    top: clonedObj.top,
                    scaleX: clonedObj.scaleX,
                    scaleY: clonedObj.scaleY,
                };
    
                clonedObj.set({
                    left: clonedObj.left * originalScaleX,
                    top: clonedObj.top * originalScaleY,
                    scaleX: clonedObj.scaleX * originalScaleX,
                    scaleY: clonedObj.scaleY * originalScaleY,
                });
                clonedObj.setCoords();
    
                // Render cloned object to the temporary canvas context
                clonedObj.render(tempCtx);
    
                // Restore original properties to avoid affecting cloned object further
                clonedObj.set(originalObjProps);
                clonedObj.setCoords();
            });
        });
    
        // Use a timeout or fabric.js's own rendering completion callbacks as needed
        setTimeout(() => {
            tempCanvasEl.toBlob(blob => {
                const link = document.createElement('a');
                link.download = 'aas-banner.png';
                link.href = URL.createObjectURL(blob);
                link.click();
                // Optionally, clean up the temporary canvas if it's not added to the DOM
            }, 'image/png');
        }, 100); // Adjust timeout based on actual rendering time observed
    };

    function loadImageAsDataURL(url, callback) {
        const img = new Image();
        img.crossOrigin = 'Anonymous'; // Attempt to request CORS access; this may fail if the server doesn't allow it.
        img.onload = function () {
            let canvas = document.createElement('canvas');
            let ctx = canvas.getContext('2d');
            canvas.height = this.naturalHeight;
            canvas.width = this.naturalWidth;
            ctx.drawImage(this, 0, 0);
            const dataURL = canvas.toDataURL('image/png');
            callback(dataURL); // Use this Data URL directly for your canvas operations
        };
        img.src = url;
        if (img.complete || img.complete === undefined) {
            img.src = "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==";
            img.src = url;
        }
    }

    const setObject = (imageUrl) => {
        setIsLoading(true);
        loadImageAsDataURL(imageUrl, function (dataUrl) {
            fabric.Image.fromURL(dataUrl, (img) => {
                img.scale(0.15).set({
                    left: 100,
                    top: 10,
                    angle: 0,
                });

                editor?.canvas.add(img);
                editor?.canvas.setActiveObject(img);
                const activeObject = editor?.canvas.getActiveObject();
                if (activeObject) {
                    activeObject.canvas.preserveObjectStacking = true;
                    editor?.canvas.requestRenderAll();
                }
                setIsLoading(false);
            });
        });
    };

    const setBackground = (input) => {
        // Check if the input is a hex color value
        const isColor = /^#([0-9A-F]{3}){1,2}$/i.test(input);

        if (isColor) {
            editor.canvas.setBackgroundImage(null, () => {
                // Once the background image is cleared, set the new background color
                editor.canvas.setBackgroundColor(input, editor.canvas.renderAll.bind(editor.canvas));
            });
        } else {
            editor.canvas.setBackgroundColor(null, () => {
                // It's not a hex color, assume it's an image URL
                fabric.Image.fromURL(input, (img) => {
                    editor.canvas.setBackgroundImage(img, editor.canvas.renderAll.bind(editor.canvas), {
                        scaleX: editor.canvas.width / img.width,
                        scaleY: editor.canvas.height / img.height,
                    });
                });
            });
        }
    };

    useEffect(() => {
        const activeText = editor?.canvas.getActiveObject();

        if (activeText && activeText.type === 'i-text') {
            activeText.set('fill', color); // Change 'red' to your preferred color
            editor?.canvas.requestRenderAll();
        }
    }, [color]);

    useEffect(() => {
        if (editor === undefined) {
            return;
        }

        fabric.Image.fromURL(BGData[0].image, (img) => {
            editor?.canvas.setBackgroundImage(img, editor?.canvas.renderAll.bind(editor?.canvas), {
                scaleX: editor?.canvas.width / img.width,
                scaleY: editor?.canvas.height / img.height,
            });
        });

        // Function to adjust canvas size according to the Twitter banner aspect ratio
        const adjustCanvasSize = () => {
            const container = document.querySelector('.sample-canvas'); // Adjust this selector to target your canvas container correctly
            const containerWidth = container.offsetWidth; // Full container width
            const twitterBannerAspectRatio = 3; // Twitter banner aspect ratio (width:height)
            const calculatedHeight = containerWidth / twitterBannerAspectRatio; // Calculate height based on aspect ratio

            // Set canvas dimensions
            editor.canvas.setWidth(containerWidth);
            editor.canvas.setHeight(calculatedHeight);
            editor.canvas.renderAll();
        };

        // Adjust canvas size on mount and on window resize
        adjustCanvasSize();
        window.addEventListener('resize', adjustCanvasSize);

        // Cleanup on component unmount
        return () => window.removeEventListener('resize', adjustCanvasSize);
    }, [editor]);

    useEffect(() => {
        fabric.Object.prototype.set({
            borderColor: 'black',
            cornerColor: 'black',
            cornerSize: 8,
            transparentCorners: false,
            cornerStyle: 'circle',
        });

        editor?.canvas.renderAll();
    }, []);

    const [searchTerm, setSearchTerm] = useState('');
    const [displayedNfts, setDisplayedNfts] = useState([]);
    const [pageIndex, setPageIndex] = useState(1);
    const PAGE_SIZE = 10;

    useEffect(() => {
        const results = searchTerm === '' ? NftsData : NftsData.filter(nft =>
            nft.name.toString() === searchTerm // Check for exact match only if searchTerm is not empty
        );
        setDisplayedNfts(results.slice(0, PAGE_SIZE));
        setPageIndex(1); // Reset page index on new search
    }, [searchTerm]);

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleShowMore = () => {
        const nextPageIndex = pageIndex + 1;
        const nextSet = NftsData.filter(nft =>
            nft.name.toLowerCase().includes(searchTerm.toLowerCase())
        ).slice(0, nextPageIndex * PAGE_SIZE);

        setDisplayedNfts(nextSet);
        setPageIndex(nextPageIndex);
    };

    return (
        <div>
            <div className='grid grid-cols-8 gap-4 mx-2 lg:mx-10'>
                <div className='col-span-4 lg:col-span-2 mode-sec-bg p-4 rounded-xl relative'>
                    {isLoading && <div className="absolute inset-0 bg-gray-200 bg-opacity-50 z-10 flex justify-center items-center">
                        <div role="status">
                            <svg aria-hidden="true" class="w-8 h-8 text-gray-200 animate-spin fill-black" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                            </svg>
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>}
                    <div class="flex items-center max-w-sm mx-auto">
                        <label for="simple-search" class="sr-only">Search</label>
                        <div class="relative w-full">
                            <input type="text" id="simple-search" value={searchTerm} onChange={handleSearchChange} class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="#" required />
                        </div>
                        <button disabled class="p-2.5 ms-2 text-sm font-medium text-white bg-[#d5d5d5] rounded-lg">
                            <svg class="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                            </svg>
                            <span class="sr-only">Search</span>
                        </button>
                    </div>
                    <div className='h-[63vh] overflow-y-auto mt-4 '>
                        <div className='grid grid-cols-2 auto-rows-max gap-4 w-full cursor-pointer'>
                            {displayedNfts.map((image) => (
                                <div key={image.id} onClick={() => { setObject(image['image-nobg']) }}>
                                    <img src={image.image} alt={image.name} />
                                </div>
                            ))}
                        </div>
                        <div className='w-full text-center my-2'>
                            {displayedNfts.length < NftsData.length && (
                                <button onClick={handleShowMore} className="more-button bg-[#dfdfdf] rounded-lg text-center px-2">
                                    Show More
                                </button>
                            )}
                        </div>
                    </div>
                </div>
                <div className='hidden lg:block lg:col-span-4 mode-sec-bg p-4 rounded-xl h-fit'>
                    <div className='flex flex-wrap'>
                        <button className='mr-2 mb-2 mode-btn-bg mode-btn-text px-2 py-2 rounded-lg inline-flex items-center text-xs 2xl:text-base' onClick={bringToFront}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-layer-forward w-5 h-5 mr-2" viewBox="0 0 16 16"> <path d="M8.354.146a.5.5 0 0 0-.708 0l-3 3a.5.5 0 0 0 0 .708l1 1a.5.5 0 0 0 .708 0L7 4.207V12H1a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1v-2a1 1 0 0 0-1-1H9V4.207l.646.647a.5.5 0 0 0 .708 0l1-1a.5.5 0 0 0 0-.708l-3-3z" /> <path d="M1 7a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h4.5a.5.5 0 0 0 0-1H1V8h4.5a.5.5 0 0 0 0-1H1zm9.5 0a.5.5 0 0 0 0 1H15v2h-4.5a.5.5 0 0 0 0 1H15a1 1 0 0 0 1-1V8a1 1 0 0 0-1-1h-4.5z" /> </svg> Forward</button>
                        <button className='mr-2 mb-2 mode-btn-bg mode-btn-text px-2 py-2 rounded-lg inline-flex items-center text-xs 2xl:text-base' onClick={sendToBack}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-layer-backward w-5 h-5 mr-2" viewBox="0 0 16 16"> <path d="M8.354 15.854a.5.5 0 0 1-.708 0l-3-3a.5.5 0 0 1 0-.708l1-1a.5.5 0 0 1 .708 0l.646.647V4H1a1 1 0 0 1-1-1V1a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H9v7.793l.646-.647a.5.5 0 0 1 .708 0l1 1a.5.5 0 0 1 0 .708l-3 3z" /> <path d="M1 9a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h4.5a.5.5 0 0 1 0 1H1v2h4.5a.5.5 0 0 1 0 1H1zm9.5 0a.5.5 0 0 1 0-1H15V6h-4.5a.5.5 0 0 1 0-1H15a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-4.5z" /> </svg> Backward</button>
                        <button className='mr-2 mb-2 mode-btn-bg mode-btn-text px-2 py-2 rounded-lg inline-flex items-center text-xs 2xl:text-base' onClick={flipHorizontal}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className='w-5 h-5 mr-2' fill='currentColor'><path d="M0 224c0 17.7 14.3 32 32 32s32-14.3 32-32c0-53 43-96 96-96H320v32c0 12.9 7.8 24.6 19.8 29.6s25.7 2.2 34.9-6.9l64-64c12.5-12.5 12.5-32.8 0-45.3l-64-64c-9.2-9.2-22.9-11.9-34.9-6.9S320 19.1 320 32V64H160C71.6 64 0 135.6 0 224zm512 64c0-17.7-14.3-32-32-32s-32 14.3-32 32c0 53-43 96-96 96H192V352c0-12.9-7.8-24.6-19.8-29.6s-25.7-2.2-34.9 6.9l-64 64c-12.5 12.5-12.5 32.8 0 45.3l64 64c9.2 9.2 22.9 11.9 34.9 6.9s19.8-16.6 19.8-29.6V448H352c88.4 0 160-71.6 160-160z" /></svg> Flip</button>
                        <button className='mr-2 mb-2 mode-btn-bg mode-btn-text px-2 py-2 rounded-lg inline-flex items-center text-xs 2xl:text-base' onClick={duplicateSelected}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" className='w-5 h-5 mr-2' fill='currentColor'><rect width="256" height="256" fill="none" /><polyline points="168 168 216 168 216 40 88 40 88 88" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="24" /><rect x="40" y="88" width="128" height="128" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="24" /></svg> Duplicate</button>
                        <button className='mr-2 mb-2 mode-btn-bg mode-btn-text px-2 py-2 rounded-lg inline-flex items-center text-xs 2xl:text-base' onClick={onAddText}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" className='w-5 h-5 mr-2' fill='currentColor'><path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z" /></svg> Add Text</button>
                        <div className='relative'>
                            <button className='mr-2 mb-2 mode-btn-bg mode-btn-text px-2 py-2 rounded-lg' onClick={() => {
                                if (IsTextColor) {
                                    setIsTextColor(false);
                                } else {
                                    setIsTextColor(true);
                                }
                            }}><svg className='w-5 h-5' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill='currentColor'><path d="M512 256c0 .9 0 1.8 0 2.7c-.4 36.5-33.6 61.3-70.1 61.3H344c-26.5 0-48 21.5-48 48c0 3.4 .4 6.7 1 9.9c2.1 10.2 6.5 20 10.8 29.9c6.1 13.8 12.1 27.5 12.1 42c0 31.8-21.6 60.7-53.4 62c-3.5 .1-7 .2-10.6 .2C114.6 512 0 397.4 0 256S114.6 0 256 0S512 114.6 512 256zM128 288a32 32 0 1 0 -64 0 32 32 0 1 0 64 0zm0-96a32 32 0 1 0 0-64 32 32 0 1 0 0 64zM288 96a32 32 0 1 0 -64 0 32 32 0 1 0 64 0zm96 96a32 32 0 1 0 0-64 32 32 0 1 0 0 64z" /></svg></button>
                            {IsTextColor &&
                                <div className='absolute mt-2 z-50'>
                                    <SketchPicker color={color} onChangeComplete={handleChangeComplete} />
                                </div>
                            }
                        </div>
                        <select className='mr-2 mb-2 mode-btn-bg mode-btn-text py-2 rounded-lg' value={currentFont} onChange={handleFontChange}>
                            <option value="Times New Roman">Times New Roman</option>
                            <option value="IMPACT">Impact</option>
                            <option value="Arial">Arial</option>
                            <option value="Courier New">Courier New</option>
                            <option value="Georgia">Georgia</option>
                        </select>
                        <button className='mr-2 mb-2 mode-btn-bg mode-btn-text px-2 py-2 rounded-lg inline-flex items-center text-xs 2xl:text-base' onClick={deleteSelected}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" className='w-4 h-4 mr-2' fill='currentColor'><path d="M135.2 17.7C140.6 6.8 151.7 0 163.8 0H284.2c12.1 0 23.2 6.8 28.6 17.7L320 32h96c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 96 0 81.7 0 64S14.3 32 32 32h96l7.2-14.3zM32 128H416V448c0 35.3-28.7 64-64 64H96c-35.3 0-64-28.7-64-64V128zm96 64c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16z" /></svg>Delete</button>
                    </div>
                    <FabricJSCanvas className="sample-canvas w-full h-auto mt-2" onReady={onReady} />
                    <button onClick={handleSaveAsImage} className="p-2 mode-btn-bg mode-btn-text rounded-lg my-2 flex">
                        Save as Image <svg className='ml-2 w-5 h-5' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill='currentColor'><path d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V274.7l-73.4-73.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L288 274.7V32zM64 352c-35.3 0-64 28.7-64 64v32c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V416c0-35.3-28.7-64-64-64H346.5l-45.3 45.3c-25 25-65.5 25-90.5 0L165.5 352H64zm368 56a24 24 0 1 1 0 48 24 24 0 1 1 0-48z" /></svg>
                    </button>
                </div>
                <div className='col-span-4 lg:col-span-2 mode-sec-bg p-4 rounded-xl'>
                    <div class="flex w-full items-center justify-center bg-grey-lighter">
                        <label class="w-full flex flex-col items-center py-3 mode-btn-bg mode-btn-text rounded-lg shadow-lg uppercase cursor-pointer">
                            <svg fill="currentColor" height="18" viewBox="0 0 24 24" width="18" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0 0h24v24H0z" fill="none" />
                                <path d="M9 16h6v-6h4l-7-7-7 7h4zm-4 2h14v2H5z" />
                            </svg>
                            <span class="mt-1 text-base leading-normal">Upload Image</span>
                            <input type='file' onChange={handleUploadImage} class="hidden" />
                        </label>
                    </div>
                    <div className='flex mt-3 space-x-2'>
                        <button
                            className={`p-2 w-full border border-[#dfdfdf] rounded-lg hover:bg-[#dfdfdf] transition-all duration-200 ${activeTab === 'BGs' ? 'mode-btn-bg mode-btn-text hover:bg-black' : ''}`}
                            onClick={() => setActiveTab('BGs')}
                        >
                            BGs
                        </button>
                        <button
                            className={`p-2 w-full border border-[#dfdfdf] rounded-lg hover:bg-[#dfdfdf] transition-all duration-200 ${activeTab === 'Colors' ? 'mode-btn-bg mode-btn-text hover:bg-black' : ''}`}
                            onClick={() => setActiveTab('Colors')}
                        >
                            Colors
                        </button>
                    </div>
                    {activeTab === 'BGs' ? (
                        <div className='grid auto-rows-max gap-4 mt-4 w-full h-[50vh] overflow-y-auto'>
                            {BGData.map((image) => (
                                <div key={image.id} onClick={() => setBackground(image.image)}>
                                    <img src={image.image} alt={image.image} />
                                </div>
                            ))}
                        </div>
                    ) : (
                        <div className='grid grid-cols-2 auto-rows-max gap-4 mt-4 w-full h-[50vh] overflow-y-auto'>
                            {colors.map((color, index) => (
                                <div key={index} onClick={() => setBackground(color)} className='w-full' style={{ backgroundColor: color, height: '100px' }}></div>
                            ))}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default DesktopCanvas;
